import { Component, Prop, Host, h } from '@stencil/core';
import { PTZIconNotificationTypes } from './types/ptz-icon-notification.types';
import { PTZIconNotificationConfig } from './types/ptz-icon-notification.enums';
import { PTZIconTypes } from '../ptz-icon/types/ptz-icon.types';

@Component({
  tag: `ptz-icon-notification`,
  styleUrl: 'ptz-icon-notification.scss',
  shadow: false,
})
export class PTZIconNotification {
  /** Quantidade de notificações */
  @Prop() amount?: number = 0;

  /** Cor da notificação */
  @Prop() noticeColor: PTZIconNotificationTypes.Color = PTZIconNotificationConfig.Color.Default;

  /** Cor do icone */
  @Prop() iconColor: PTZIconTypes.Color;

  /** Nome do ícone no botão */
  @Prop() iconName: PTZIconTypes.Name;

  /** Variante do ícone no botão */
  @Prop() iconVariant: PTZIconTypes.Variant;

  /** Estado de renderização */
  @Prop() skeleton: boolean = false;

  render() {
    return (
      <Host>
        <div class="ptz-icon-notification">
        {this.skeleton ? (
            <ptz-skeleton width={24} height={24} rounded="max"></ptz-skeleton>
          ) : (
            <ptz-icon name={this.iconName} variant={this.iconVariant} color={this.iconColor} size="lg"></ptz-icon>
          )}
          {this.amount > 0 ? (
            this.skeleton ? (
              <ptz-skeleton class={'ptz-icon-notification-skeleton'} width={22} height={22} rounded="max"></ptz-skeleton>
            ) : (
              <ptz-notice amount={this.amount} color={this.noticeColor} />
            )
          ) : null}
        </div>
      </Host>
    );
  }
}
