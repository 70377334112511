import { EnumToArray } from '../../../utils/enumToArray';

enum Color {
  Default = 'default',
  Brand = 'brand',
}

export const PTZIconNotificationConfigList = {
  Color: EnumToArray([Color]),
};

export const PTZIconNotificationConfig = {
  Color,
};
