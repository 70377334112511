import { EnumToArray } from '../../../utils/enumToArray';

enum Color {
  Default = 'default',
  Brand = 'brand',
}

export const PTZNoticeConfigList = {
  Color: EnumToArray([Color]),
};

export const PTZNoticeConfig = {
  Color,
};
