ptz-icon-notification {
  display: flex;

  .ptz-icon-notification {
    display: inline-block;
    position: relative;

    ptz-notice {
      top: -$petz-spacing-xs;
      right: 0.65rem;
    }

    &-skeleton {
      position: absolute;
      top: -$petz-spacing-xs;
      left: $petz-spacing-sm;
    }
  }
}
