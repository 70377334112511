import { Component, Prop, Host, h } from '@stencil/core';
import { PTZNoticeTypes } from './types/ptz-notice.types';
import { PTZNoticeConfig } from './types/ptz-notice.enums';

@Component({
  tag: `ptz-notice`,
  styleUrl: 'ptz-notice.scss',
  shadow: false
})

export class PTZNotice {
  /** Quantidade de notificações */
  @Prop({ mutable: true }) amount?: number = 0;

  /** Cor da notificação */
  @Prop() color: PTZNoticeTypes.Color = PTZNoticeConfig.Color.Default;

  private _formatedAmount(): number {
    if (this.amount <= 0) return 0;
    return this.amount >= 99 ? 99 : this.amount;
  }

  render() {
    return (
      <Host>
        <div class={`ptz-notice ptz-notice-${this.color}`}>
          <p class="ptz-notice-amount">{this._formatedAmount()}</p>
        </div>
      </Host>
    );
  }
}
