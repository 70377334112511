ptz-notice {
  position: absolute;

  .ptz-notice {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: $petz-spacing-4xs $petz-spacing-3xs;
    position: absolute;
    width: 1.25rem;
    height: 1.25rem;
    border-radius: $petz-rounded-max;

    &-default {
      background: $petz-color-sup-red-darker-accent;
    }

    &-brand {
      background: $petz-color-primary-brand;
    }

    &-amount {
      margin: 0;
      padding: 0;
      font-weight: $petz-font-weight-bold;
      font-size: $petz-font-size-3xs;
      line-height: $petz-font-lineheight-lg;
      color: $petz-color-neutral-white;
    }
  }
}
